import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {compose} from "recompose";
import {xml2json} from 'xml-js';
import Button from "@material-ui/core/Button/Button";
import * as parser from '../../utils/parser_functions'
import {Loader} from "react-overlay-loader";
import 'react-overlay-loader/styles.css';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: 'auto%',
        height: 'auto%',
    },
    container: {
        minWidth: 320,
        maxWidth: 400,
        height: 'auto',
        left: 0,
        right: 0,
        margin: 'auto'
    },
    grow: {
        flexGrow: 1,
    },
    button: {
        minWidth: 320,
        margin: 10,

    },

});

let fileReader;

class Converter extends React.Component {

    state = {
        json: null,
        blob: null,
        loading: false
    };

    handleLoadEnd = e => {
        if (fileReader) {
            const content = fileReader.result;
            let json = JSON.parse(xml2json(content, {compact: true, ignoreComment: true, spaces: 4}));
            const blob = parser.getFileBlob(json);

            this.setState({
                json,
                blob,
                loading: false,
            });
        }
    };

    handleDownload = e => {
        let element = document.createElement("a");
        element.href = URL.createObjectURL(this.state.blob);
        element.download = "corte_certo.txt";
        element.click();
    };

    handleJsonDownload = e => {
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.state.json));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", "export.json");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    onDrop = async e => {
        let file = e.target.files[0];
        if (file) {
            this.setState({loading: true});
            fileReader = new FileReader();
            fileReader.onloadend = this.handleLoadEnd;
            setTimeout(function () {
                fileReader.readAsText(file);
            }, 200)
        }
    };

    render() {
        const {classes} = this.props;
        const {loading} = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    <input
                        accept='.xml'
                        className={classes.input}
                        style={{display: 'none'}}
                        id="raised-button-file"
                        onChange={this.onDrop}
                        type="file"/>


                    <label htmlFor="raised-button-file">
                        <Button disabled={loading} color="primary" variant="contained" component="span" className={classes.button}>
                            Upload .xml
                        </Button>
                    </label>

                    {this.state.json ?
                        < Button onClick={this.handleJsonDownload} variant="contained" color="primary" component="span"
                                 className={classes.button}>
                            Download .json
                        </Button> : null}


                    {this.state.blob ?
                        < Button onClick={this.handleDownload} variant="contained" color="primary" component="span"
                                 className={classes.button}>
                            Download .txt
                        </Button> : null}

                </div>
                <Loader loading={loading}/>
            </div>
        );
    }
}


Converter.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default compose(
    withStyles(styles)
)(Converter);
