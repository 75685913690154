import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function projectReducer(state = initialState.projectsResponse, action) {
    switch (action.type) {
        case types.LOAD_PROJECTS_SUCCESS:
            state.projects = action.projects;
            return JSON.parse(JSON.stringify(state));
        default:
            return state;
    }
}
