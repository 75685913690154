import * as types from './actionTypes';
import pieceApi from '../api/PieceApi';

export function getPieceSuccess(piece) {
    return {type: types.GET_PIECE_SUCCESS, piece};
}

export function getPieceToPackSuccess(pieces) {
    return {type: types.GET_PIECE_TO_PACK, pieces};
}

export function getPiecesSuccess(pieces) {
    return {type: types.GET_PIECES_SUCCESS, pieces};
}

export function updatePieceSuccess(piece) {
    return {type: types.UPDATE_PIECE_SUCCESS, piece}
}

export function checkPieceSuccess(piece) {
    return {type: types.CHECK_PIECE_SUCCESS, piece}
}

export function createPieceSuccess(piece) {
    return {type: types.CREATE_PIECE_SUCCESS, piece}
}

export function createPiecesSuccess(pieces) {
    return {type: types.CREATE_PIECES_SUCCESS, pieces}
}

export function packPiecesSuccess(pack) {
    return {type: types.PACK_PIECES_SUCCESS, pack}
}

export function deletePieceSuccess(piece) {
    return {type: types.DELETE_PIECE_SUCCESS, piece}
}

export function getPiece(pieceId, projectId) {
    // make async call to api, handle promise, dispatch action when promise is resolved
    return function (dispatch) {
        return pieceApi.getPiece(pieceId, projectId).then(piece => {
            dispatch(getPieceSuccess(piece));
        }).catch(error => {
            throw(error);
        });
    };
}

export function getPieceByOrder(pieceId, order) {
    // make async call to api, handle promise, dispatch action when promise is resolved
    return function (dispatch) {
        return pieceApi.getPieceByOrder(pieceId, order).then(piece => {
            dispatch(getPieceSuccess(piece));
        }).catch(error => {
            throw(error);
        });
    };
}


export function getPieceToPack(pieceId, projectId) {
    // make async call to api, handle promise, dispatch action when promise is resolved
    return function (dispatch) {
        return pieceApi.getPiece(pieceId, projectId).then(pieces => {
            dispatch(getPieceToPackSuccess(pieces));
        }).catch(error => {
            throw(error);
        });
    };
}

export function getPieces(projectId) {
    // make async call to api, handle promise, dispatch action when promise is resolved
    return function (dispatch) {
        return pieceApi.getPieces(projectId).then(pieces => {
            dispatch(getPiecesSuccess(pieces));
        }).catch(error => {
            throw(error);
        });
    };
}

export function getPiecesByOrder(order) {
    // make async call to api, handle promise, dispatch action when promise is resolved
    return function (dispatch) {
        return pieceApi.getPiecesByOrder(order).then(pieces => {
            dispatch(getPiecesSuccess(pieces));
        }).catch(error => {
            throw(error);
        });
    };
}

export function packPieces(projectId, pieces) {
    return function (dispatch) {
        return pieceApi.packPieces(projectId, pieces).then(responsePieces => {
            dispatch(packPiecesSuccess(responsePieces));
            return responsePieces;
        }).catch(error => {
            throw(error);
        });
    };
}

export function createPieces(pieces) {
    return function (dispatch) {
        return pieceApi.createPieces(pieces).then(responsePieces => {
            dispatch(createPiecesSuccess(responsePieces));
            return responsePieces;
        }).catch(error => {
            throw(error);
        });
    };
}

export function createPiece(piece) {
    return function (dispatch) {
        return pieceApi.createPiece(piece).then(responsePiece => {
            dispatch(createPieceSuccess(responsePiece));
            return responsePiece;
        }).catch(error => {
            throw(error);
        });
    };
}

export function checkPiece(pieceId, order) {
    return function (dispatch) {
        return pieceApi.checkPiece(pieceId, order).then(responsePiece => {
            dispatch(checkPieceSuccess(responsePiece));
        }).catch(error => {
            throw(error);
        });
    };
}

export function updatePiece(piece) {
    return function (dispatch) {
        return pieceApi.updatePiece(piece).then(responsePiece => {
            dispatch(updatePieceSuccess(responsePiece));
        }).catch(error => {
            throw(error);
        });
    };
}


export function deletePiece(piece) {
    return function (dispatch) {
        return pieceApi.deletePiece(piece).then(() => {
            console.log(`Deleted ${piece.id}`);
            dispatch(deletePieceSuccess(piece));
            return;
        }).catch(error => {
            throw(error);
        })
    }
}







