module.exports = Object.freeze({

    /**
     * STATUS
     */
    STATUS_CUT: 'CUT',
    STATUS_PENDING_CUT: 'PENDING_CUT',
    STATUS_PACKED: 'PACKED',
    STATUS_ON_BOARD: 'ON_BOARD',
    STATUS_DELIVERED: 'DELIVERED',
    STATUS_CREATED: 'CREATED',
    STATUS_CHECKED: 'CHECKED',
    STATUS_USED: 'USED',

    MENU_PROJECTS: 0,
    MENU_CONVERTER: 1,
    MENU_IMPORTER: 2,

});

