import {API_ROOT} from './apiConfig';

class VolumeApi {

    static requestHeaders() {
        return {}
    }

    static getVolumes(projectId) {
        const headers = this.requestHeaders();
        const request = new Request(`${API_ROOT}/volume/project/${projectId}`, {
            method: 'GET',
            headers: headers
        });

        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }

    static checkVolume(volumeId, projectId) {
        const headers = Object.assign({'Content-Type': 'application/json'}, this.requestHeaders());
        const request = new Request(`${API_ROOT}/volume/check/${volumeId}/project/${projectId}`, {
            method: 'PUT',
            headers: headers,
        });

        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }
}


export default VolumeApi;
