import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.css';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
import configureStore from "./store/configureStore";
import ThemeDefault from "./theme-default";
import { MuiThemeProvider } from '@material-ui/core/styles';

const store = configureStore();

ReactDOM.render(
    <MuiThemeProvider theme={ThemeDefault}>
        <Provider store={store}>
            <App/>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
)
;

registerServiceWorker();
