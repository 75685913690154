import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ConvertFile from '@material-ui/icons/ImportExport';
import ImportFile from '@material-ui/icons/CloudUpload';
import ListProjects from '@material-ui/icons/ListAlt';
import {onChangeMenuItem} from "../../actions";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import constants from '../../utils/constants'


class MenuListItems extends React.Component {


    render() {
        const {currentItem} = this.props.currentItem;
        return (
            <div>
                <ListItem button onClick={() => this.props.onChangeMenuItem(constants.MENU_PROJECTS)} selected={currentItem === constants.MENU_PROJECTS}>
                    <ListItemIcon>
                        <ListProjects/>
                    </ListItemIcon>
                    <ListItemText primary="Projetos"/>
                </ListItem>

                <ListItem button onClick={() => this.props.onChangeMenuItem(constants.MENU_IMPORTER)} selected={currentItem === constants.MENU_IMPORTER}>
                    <ListItemIcon>
                        <ImportFile/>
                    </ListItemIcon>
                    <ListItemText primary="Importar Projeto"/>
                </ListItem>

                <ListItem button onClick={() => this.props.onChangeMenuItem(constants.MENU_CONVERTER)} selected={currentItem === constants.MENU_CONVERTER}>
                    <ListItemIcon>
                        <ConvertFile/>
                    </ListItemIcon>
                    <ListItemText primary="Conversor"/>
                </ListItem>




            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentItem: state.menuState
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({onChangeMenuItem: onChangeMenuItem}, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(MenuListItems);