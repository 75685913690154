import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {auth} from '../../firebase';
import * as routes from '../../constants/routes';
import {Snackbar} from '@material-ui/core';
import {grey500} from 'material-ui/styles/colors';
import {Button} from "@material-ui/core/es/index";
import TextField from "@material-ui/core/es/TextField/TextField";
import {API_HOST} from "../../api/apiConfig";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import Card from "@material-ui/core/Card/Card";


const styles = {
    container: {
        minWidth: 320,
        maxWidth: 400,
        height: 'auto',
        position: 'absolute',
        top: '20%',
        left: 0,
        right: 0,
        margin: 'auto'
    },
    paper: {
        padding: 20,
        overflow: 'auto'
    },
    buttonsDiv: {
        textAlign: 'center',
        paddingTop: 20,
        paddingBottom: 10,
    },
    flatButton: {
        color: grey500
    },
    loginBtn: {
        float: 'center'
    },
    media: {
        height: 140,
        marginBottom: 20,
    },
    snackbar: {
        position: 'absolute',
    },

};


const SignInPage = ({history}) => <SignInForm history={history}/>;

const updateByPropertyName = (propertyName, value) => () => ({
    [propertyName]: value,
});

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInForm extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    onSubmit = (event) => {
        const {
            email,
            password,
        } = this.state;

        const {
            history,
        } = this.props;

        auth.doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState(() => ({...INITIAL_STATE}));
                history.push(routes.HOME);
            })
            .catch(error => {
                console.log(error);
                this.setState(updateByPropertyName('error', error));
            });

        event.preventDefault();
    };

    handleClose = (event, reason) => {
        this.setState(updateByPropertyName('error', null));
    };

    render() {
        const {
            error,
        } = this.state;

        return (
            <div>
                <div style={styles.container}>
                    <Card
                        style={styles.paper}>

                        <CardMedia
                            image={`${API_HOST}/images/cl.png`}
                            title="Contemplative Reptile"
                            style={styles.media}
                        />
                        <form>
                            <TextField
                                label="E-mail"
                                floatingLabelText="E-mail"
                                fullWidth={true}
                                onChange={event => {
                                    this.setState(updateByPropertyName('email', event.target.value));
                                    this.setState(updateByPropertyName('error', null));
                                }}
                            />
                            <TextField
                                label="Password"
                                floatingLabelText="Password"
                                fullWidth={true}
                                type="password"
                                onChange={event => {
                                    this.setState(updateByPropertyName('password', event.target.value));
                                    this.setState(updateByPropertyName('error', null));
                                }}
                            />

                            <div style={styles.buttonsDiv}>
                                < Button
                                    onClick={this.onSubmit}
                                    variant="raised"
                                    color="primary"
                                    component="span"
                                    style={styles.loginBtn}>
                                    Login
                                </Button>

                            </div>
                        </form>

                        <Snackbar
                            open={error != null}
                            onClose={this.handleClose}
                            autoHideDuration={4000}
                            message={<span id="snackbar-fab-message-id">Invalid email/password.</span>}
                            style={styles.snackbar}
                        />

                    </Card>

                    <div style={styles.buttonsDiv}>

                        < Button href={routes.PASSWORD_FORGET}>
                            Forgot Password?
                        </Button>
                    </div>


                </div>
            </div>
        )
    }
}

export default withRouter(SignInPage);

export {
    SignInForm,
};

