export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';
export const DELIVER_PROJECT_SUCCESS = 'DELIVER_PROJECT_SUCCESS';

export const GET_PIECE_SUCCESS = 'GET_PIECE_SUCCESS';
export const GET_PIECE_TO_PACK = 'GET_PIECE_TO_PACK';
export const GET_PIECES_SUCCESS = 'GET_PIECES_SUCCESS';
export const UPDATE_PIECE_SUCCESS = 'UPDATE_PIECE_SUCCESS';
export const CHECK_PIECE_SUCCESS = 'CHECK_PIECE_SUCCESS';
export const CREATE_PIECE_SUCCESS = 'CREATE_PIECE_SUCCESS';
export const CREATE_PIECES_SUCCESS = 'CREATE_PIECES_SUCCESS';
export const PACK_PIECES_SUCCESS = 'PACK_PIECES_SUCCESS';
export const DELETE_PIECE_SUCCESS = 'DELETE_PIECE_SUCCESS';


export const GET_VOLUMES_SUCCESS = 'GET_VOLUMES_SUCCESS';
export const CHECK_VOLUMES_SUCCESS = 'CHECK_VOLUMES_SUCCESS';
