import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {compose} from "recompose";
import 'react-overlay-loader/styles.css';
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import Typography from "@material-ui/core/Typography/Typography";
import {API_HOST} from "../../api/apiConfig";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {Column, Row} from 'simple-flexbox'

const styles = theme => ({
        chip: {
            margin: theme.spacing.unit,
            marginRight: 30,
            marginLeft: 30,
        },
        greenAvatar: {
            color: '#fff',
            backgroundColor: green[500],
        },
        yellowAvatar: {
            color: '#fff',
            backgroundColor: yellow[800],
        },
        redAvatar: {
            color: '#fff',
            backgroundColor: red[500],
        },
        image: {
            marginTop: 10,
            height: 40,
        },
        tag: {
            position: 'absolute',
            padding: 50,
            width: '210mm',
            minHeight: '297mm',
            zIndex: -5,
        },
        piecesList: {
            float: 'left',
        },
        clientName: {
            color: '#000',
            marginTop: 20,
        },

        phone: {
            color: '#000',
            marginTop: 5,
        },
        volume: {
            marginTop: 10,
            color: '#504d4d',
            fontWeight: 'bold',
        },
        black: {
            color: '#000',
        },
        listContainer: {
            marginTop: 10,
            columnCount: 2,
        },
        title: {
            bottom: 0,
            right: 0,
            float: 'center',
            textAlign: 'center',
        },
        table: {
            width: '100%',
            textAlign: 'center',
        },
        signatures: {
            marginTop: 100,
        },
        date: {
            marginTop: 20,
        },
        line: {
            margin: 10,
            width: '90%',
            height: 0.2,
            borderStyle: 'solid',
            borderWidth: 0.2
        },
    })
;

class DeliveryOrder extends React.Component {
    state = {};

    getFormattedDate = () => {
        const date = new Date();
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        return day + '/' + monthIndex + '/' + year;
    };


    getPieceStatusComponent(project) {
        const {classes} = this.props;
        if (!project) {
            return <div/>;
        } else {
            return <div className={classes.tag}>
                <div className={classes.title}>
                    <img className={classes.image} src={`${API_HOST}/images/cl_h_fundo_branco.png`}/>
                    <Typography variant="h5" className={classes.volume}>Ordem de Entrega</Typography>
                </div>

                <Typography variant="h6" className={classes.clientName}><b>Cliente: </b> {project.client}</Typography>
                <Typography variant="h6" className={classes.black}><b>Projeto: </b> {project.name}</Typography>
                <Typography variant="h6" className={classes.black}><b>Endereço:</b> {project.address}</Typography>


                <Table className={classes.listContainer}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Descrição</TableCell>
                            <TableCell numeric>Ambiente</TableCell>
                            <TableCell numeric>ID do Módulo</TableCell>
                            <TableCell numeric>ID da Peça</TableCell>
                            <TableCell numeric>Tamanho</TableCell>
                            <TableCell numeric>Volume</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>


                        {project.pieces.map(piece => {
                            return (
                                <TableRow key={piece.id}>
                                    <TableCell component="th" scope="row">
                                        {piece.name}
                                    </TableCell>
                                    <TableCell>{piece.ambient}</TableCell>
                                    <TableCell>{piece.moduleId}</TableCell>
                                    <TableCell>{piece.pieceId}</TableCell>
                                    <TableCell>{piece.width + " X " + piece.height}</TableCell>
                                    <TableCell>{piece.volume.name.replace('volume', 'V')}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>


                <Row className={classes.signatures} vertical='center' horizontal='space-between'>
                    <Column flexGrow={1} horizontal='center'>
                        <div className={classes.line}/>
                        <Typography variant="Subheading" className={classes.clientName}>Assinatura do responsável
                            (expedição)</Typography>
                    </Column>
                    <Column flexGrow={1} horizontal='center'>
                        <div className={classes.line}/>
                        <Typography variant="Subheading" className={classes.clientName}>Assinatura do cliente
                            (recebimento)</Typography>
                    </Column>
                </Row>

                <Row className={classes.date} vertical='center'>
                    <Column flexGrow={1} horizontal='center'>
                        <Typography variant="Subheading" className={classes.clientName}>Campina
                            Grande, {this.getFormattedDate()}</Typography>
                    </Column>
                </Row>
            </div>
        }
    };

    render() {
        const {project} = this.props;
        return this.getPieceStatusComponent(project);
    }
}

DeliveryOrder.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
)(DeliveryOrder);