import React from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getVolumes, checkVolume} from '../../actions/volumeActions';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import PieceStatus from "../Status/PieceStatus";
import Tag from "../Printables/Tag";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip/Chip";
import Avatar from "@material-ui/core/Avatar/Avatar";
import CheckIcon from '@material-ui/icons/Check';
import Collapse from "@material-ui/core/Collapse/Collapse";
import blue from "@material-ui/core/colors/blue";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReactToPrint from "react-to-print";
import DeliveryOrder from "../Printables/DeliveryOrder";
import green from "@material-ui/core/colors/green";
import * as constants from "../../utils/constants";

const styles = theme => ({
    dialog: {
        backgroundColor: '#fff',
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    pieceIdLayout: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
        flex: 1,
    },
    pieceIdTextField: {
        width: 300,
        flex: 1,
    },
    pieceIdFromGroup: {
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        width: 300,
    },
    greenAvatar: {
        color: '#fff',
        backgroundColor: green[500],
    },
    list: {
        marginBottom: 30,
    },

    listItem: {
        '&:hover, &:focus': {
            backgroundColor: blue[50],
        },
        '&:active': {
            backgroundColor: blue[50],
        },
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },

    hidden: {display: 'none'},

    tagCover: {
        backgroundColor: '#fff',
        position: 'absolute',
        padding: 10,
        width: '11cm',
        height: '11cm',
        zIndex: -5,
    },

    deliveryOrderCover: {
        backgroundColor: '#fff',
        position: 'absolute',
        padding: 50,
        width: '220mm',
        height: '307mm',
        zIndex: -5,
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class VolumeCheckDialog extends React.Component {
    constructor(props) {
        super();
        this.isFromBarcodeReader = false;
        this.lastTimeStamp = null;
    }

    componentDidUpdate() {
        if (this.props.project && !this.state.isVolumesLoaded && this.props.openCheckVolumesDialog) {
            this.props.getVolumes(this.props.project._id);
            this.setState({isVolumesLoaded: true});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.openCheckVolumesDialog && nextProps.project && nextProps.volumes && (this.state.lastVolumesLength == null || nextProps.volumes.length !== this.state.lastVolumesLength)) {
            this.setState({lastVolumesLength: nextProps.volumes.length});
            this.props.getVolumes(nextProps.project._id);
        }
    }

    state = {
        currentVolumeId: "",
        lastPack: null,
        isVolumesLoaded: false,
        isTagAssociated: false,
        lastVolumesLength: null,
    };

    handleClick = (volumeId) => {
        let value;
        if (volumeId in this.state) {
            value = !this.state[volumeId];
        } else {
            value = true;
        }
        this.setState({[volumeId]: value});
    };

    handleClose = (e) => {
        this.props.onCloseCheckVolumesDialog(e);
        this.setState({
            isVolumesLoaded: false,
            isTagAssociated: false,
        });

    };

    handleCheckVolume = (e, volumeId) => {
        this.props.checkVolume(volumeId, this.props.project._id);
        this.setState({currentVolumeId: ""});
    };

    handleKeyPress = (e, volumeId) => {
        if (/^\d$/.test(e.key)) {
            if (this.lastTimeStamp) {
                let diff = e.timeStamp - this.lastTimeStamp;
                this.isFromBarcodeReader = diff < 10;
            }
            this.lastTimeStamp = e.timeStamp;
        } else if (e.key === 'Enter') {
            this.handleCheckVolume(e, volumeId);
            this.isFromBarcodeReader = false;
        }
    };

    render() {
        const {
            classes,
            openCheckVolumesDialog,
            project,
            volumes,
            error
        } = this.props;

        if (volumes) {
            return (
                <div className={classes.dialog}>
                    <Dialog
                        fullScreen
                        open={openCheckVolumesDialog}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="h6" color="inherit" className={classes.flex}>
                                    Conferir volumes ({project.name})
                                </Typography>
                                <Button color="inherit" onClick={this.handleClose}>
                                    Finalizar
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <div>
                            <div className={classes.pieceIdLayout}>
                                <Paper>
                                    {project.status === constants.STATUS_ON_BOARD ?
                                        <FormGroup className={classes.pieceIdFromGroup}>
                                            <ReactToPrint
                                                trigger={() => <div>
                                                    <Button variant="contained" color="primary"
                                                            className={classes.button}>
                                                        Imprimir ordem de entrega
                                                    </Button>
                                                </div>}
                                                content={() => this.deliveryOrderRef}/>
                                            <DeliveryOrder project={project} ref={el => (this.deliveryOrderRef = el)}/>
                                            <div className={classes.deliveryOrderCover}/>
                                        </FormGroup>
                                        :
                                        <FormGroup className={classes.pieceIdFromGroup}>
                                            <TextField
                                                onKeyPress={(e) => this.handleKeyPress(e, this.state.currentVolumeId)}
                                                error={error}
                                                autoFocus={true}
                                                className={classes.pieceIdTextField}
                                                label="ID do volume"
                                                fullWidth={true}
                                                value={this.state.currentVolumeId}
                                                onChange={event => {
                                                    this.setState({currentVolumeId: event.target.value});
                                                }}/>

                                            <Button
                                                onClick={(e) => this.handleCheckVolume(e, this.state.currentVolumeId)}
                                                color="primary"
                                                variant="contained"
                                                className={classes.button}>
                                                Conferir volume
                                            </Button>
                                        </FormGroup>
                                    }
                                </Paper>
                            </div>

                            <List className={classes.list}>
                                {volumes.map((volume) => (
                                    <div key={volume._id}>
                                        <ListItem className={classes.listItem}
                                                  onClick={(e) => this.handleClick(volume.volumeId)}>
                                            <ListItemText primary={
                                                <Typography variant="h5"
                                                            style={{color: '#9b2025'}}>{volume.name}</Typography>}
                                            />

                                            {volume.status === constants.STATUS_ON_BOARD &&
                                            <Chip
                                                avatar={
                                                    <Avatar className={classes.greenAvatar}>
                                                        <CheckIcon className={classes.icon}/>
                                                    </Avatar>
                                                }
                                                label="Conferido"
                                                className={classes.chip}/>
                                            }

                                            <ReactToPrint
                                                trigger={() => <div>
                                                    <Button variant="contained" color="primary"
                                                            className={classes.button}>
                                                        Imprimir etiqueta
                                                    </Button>
                                                </div>}
                                                content={() => this.state[volume._id]}/>

                                            <Tag volume={volume} ref={el => {
                                                if (!this.state[volume._id] || (!this.state.isTagAssociated && this.state.isVolumesLoaded)) {
                                                    this.setState({[volume._id]: el, isTagAssociated: true})
                                                }
                                            }}/>
                                            <div className={classes.tagCover}/>

                                            {this.state[volume.volumeId] ? <ExpandLess/> : <ExpandMore/>}
                                        </ListItem>

                                        <Collapse in={this.state[volume.volumeId]} timeout="auto" unmountOnExit>
                                            <List>
                                                {volume.pieces.map((piece) => (
                                                    <ListItem key={piece._id}>
                                                        <ListItemText primary={<div>
                                                            <Typography variant="h6"
                                                                        style={{color: '#444fb1'}}>{piece.name}</Typography>
                                                            <Typography
                                                                style={{color: '#a7a7a7'}}>Tamanho: {piece.width + " x " + piece.height}</Typography>
                                                            <Typography style={{color: '#a7a7a7'}}>ID da
                                                                peça: {piece.pieceId}</Typography>
                                                        </div>}/>
                                                        <PieceStatus piece={piece}/>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </div>
                                ))}
                            </List>
                        </div>
                    </Dialog>
                </div>
            );
        } else {
            return (
                <div/>
            );
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getVolumes: getVolumes,
        checkVolume: checkVolume,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        volumes: state.volumes.volumes,
    };
}

VolumeCheckDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(VolumeCheckDialog);