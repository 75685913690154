import React, {Component} from 'react';
import {auth} from '../../firebase';
import {grey500, grey600} from 'material-ui/styles/colors';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ThemeDefault from '../../theme-default';
import {Card, CardTitle} from 'material-ui/Card';
import Snackbar from 'material-ui/Snackbar';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/es/TextField/TextField";

const styles = {
    container: {
        minWidth: 320,
        maxWidth: 400,
        height: 'auto',
        position: 'absolute',
        top: '20%',
        left: 0,
        right: 0,
        margin: 'auto'
    },
    paper: {
        padding: 20,
        overflow: 'auto'
    },
    buttonsDiv: {
        textAlign: 'center',
        paddingTop: 20,
        paddingBottom: 10,
    },
    title: {
        textAlign: 'center',
        color: grey500,
    }
};

const PasswordForgetPage = () =>
    <div>
        <PasswordForgetForm/>
    </div>;

const updateByPropertyName = (propertyName, value) => () => ({
    [propertyName]: value,
});

const INITIAL_STATE = {
    email: '',
    error: null,
    isDialogOpen: false,
};


class PasswordForgetForm extends Component {
    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};
    }

    onSubmit = (event) => {
        const {email} = this.state;


        auth.doPasswordReset(email)
            .then(() => {
                this.setState(updateByPropertyName('isDialogOpen', true));
            })
            .catch(error => {
                console.log(error.message);
                this.setState(updateByPropertyName('error', error));
            });

        event.preventDefault();
    };

    handleClose = (event, reason) => {
        this.setState(updateByPropertyName('isDialogOpen', false));
    };

    render() {
        const {
            email,
            error,
        } = this.state;

        return (
            <MuiThemeProvider muiTheme={ThemeDefault}>
                <div>
                    <div style={styles.container}>
                        <Card
                            style={styles.paper}>

                            <CardTitle titleColor={grey600}
                                       title={'Reset Password'}
                                       style={styles.title}/>

                            <form>
                                <TextField
                                    label="E-mail"
                                    floatingLabelText="E-mail"
                                    fullWidth={true}
                                    onChange={event => {
                                        this.setState(updateByPropertyName('email', event.target.value));
                                        this.setState(updateByPropertyName('error', null));
                                    }}
                                />

                                <div style={styles.buttonsDiv}>

                                    < Button
                                        onClick={this.onSubmit}
                                        variant="raised"
                                        color="primary"
                                        style={styles.loginBtn}>
                                        Reset my password
                                    </Button>
                                </div>
                            </form>
                        </Card>
                        <Snackbar
                            open={error != null}
                            onClose={this.handleClose}
                            autoHideDuration={4000}
                            message={<span id="snackbar-fab-message-id">Invalid email</span>}
                            style={styles.snackbar}
                        />
                    </div>

                    <Dialog
                        open={this.state.isDialogOpen}>
                        <DialogTitle id="alert-dialog-title">{"We're almost there"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"We sent an email to " + email + " with the instructions to change your password."}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            < Button variant="raised"
                                     color="primary"
                                     component="span"
                                     autoFocus
                                     onClick={this.handleClose}>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            </MuiThemeProvider>
        );
    }
}

export default PasswordForgetPage;

export {
    PasswordForgetForm,
};
