import {combineReducers} from 'redux';
import sessionReducer from './sessionReducer';
import userReducer from './userReducer';
import menuReducer from "./menuReducer";
import projects from "./projectReducer";
import pieces from "./pieceReducer";
import volumes from "./volumeReducer";

const rootReducer = combineReducers({
    sessionState: sessionReducer,
    userState: userReducer,
    menuState: menuReducer,
    projects,
    pieces,
    volumes,
});

export default rootReducer;
