import {API_ROOT} from './apiConfig';

class ProjectApi {

    static requestHeaders() {
        return {}
    }

    static getAllProjects() {
        const headers = this.requestHeaders();
        const request = new Request(`${API_ROOT}/project`, {
            method: 'GET',
            headers: headers
        });

        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }

    static createProject(project) {
        const headers = Object.assign({'Content-Type': 'application/json'}, this.requestHeaders());
        const request = new Request(`${API_ROOT}/project`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(project)
        });


        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }

    static deliverProject(project) {
        const headers = Object.assign({'Content-Type': 'application/json'}, this.requestHeaders());
        const request = new Request(`${API_ROOT}/project/deliver/${project._id}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({project: project})
        });


        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }

    static updateProject(project) {
        const headers = Object.assign({'Content-Type': 'application/json'}, this.requestHeaders());
        const request = new Request(`${API_ROOT}/project/${project.id}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({project: project})
        });


        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }


    static deleteProject(project) {
        const headers = Object.assign({'Content-Type': 'application/json'}, this.requestHeaders());
        const request = new Request(`${API_ROOT}/project/${project.id}`, {
            method: 'DELETE',
            headers: headers
        });

        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }
}

export default ProjectApi;
