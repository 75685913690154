export function authUserAction(authUser) {
    return {
        type: 'AUTH_USER_SET',
        authUser: authUser
    }
}

export function onChangeMenuItem(currentMenuItem) {
    return {
        type: 'MENU_ITEM_CLICKED',
        currentItem: currentMenuItem
    }
}