import {API_ROOT} from "./apiConfig";

class PiecesApi {

    static requestHeaders() {
        return {}
    }

    static getPiece(pieceId, projectId) {
        const headers = this.requestHeaders();
        const request = new Request(`${API_ROOT}/piece/module/${pieceId}/project/${projectId}`, {
            method: 'GET',
            headers: headers
        });

        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }

    static getPieceByOrder(pieceId, order) {
        const headers = this.requestHeaders();
        const request = new Request(`${API_ROOT}/piece/${pieceId}/order/${order}`, {
            method: 'GET',
            headers: headers
        });

        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }

    static getPieces(projectId) {
        const headers = this.requestHeaders();
        const request = new Request(`${API_ROOT}/piece/project/${projectId}`, {
            method: 'GET',
            headers: headers
        });

        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }

    static getPiecesByOrder(order) {
        const headers = this.requestHeaders();
        const request = new Request(`${API_ROOT}/piece/order/${order}`, {
            method: 'GET',
            headers: headers
        });

        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }

    static createPieces(pieces) {
        const headers = Object.assign({'Content-Type': 'application/json'}, this.requestHeaders());
        const request = new Request(`${API_ROOT}/piece/list`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(pieces)
        });


        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }


    static packPieces(projectId, pieces) {
        const headers = Object.assign({'Content-Type': 'application/json'}, this.requestHeaders());
        const request = new Request(`${API_ROOT}/piece/pack/project/${projectId}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(pieces)
        });

        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }

    static checkPiece(pieceId, order) {
        const headers = Object.assign({'Content-Type': 'application/json'}, this.requestHeaders());
        const request = new Request(`${API_ROOT}/piece/check/${pieceId}/order/${order}`, {
            method: 'PUT',
            headers: headers,
        });

        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }

    static updatePiece(piece) {
        const headers = Object.assign({'Content-Type': 'application/json'}, this.requestHeaders());
        const request = new Request(`${API_ROOT}/piece/${piece.id}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({piece: piece})
        });


        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }


    static deletePiece(piece) {
        const headers = Object.assign({'Content-Type': 'application/json'}, this.requestHeaders());
        const request = new Request(`${API_ROOT}/piece/${piece.id}`, {
            method: 'DELETE',
            headers: headers
        });

        return fetch(request).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }
}

export default PiecesApi;
