import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {compose} from "recompose";
import {csvJSON} from '../../utils/csv_to_json';
import Button from "@material-ui/core/Button/Button";
import {Loader} from "react-overlay-loader";
import {createPieces} from '../../actions/pieceActions';
import 'react-overlay-loader/styles.css';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";


const styles = theme => ({
    root: {
        flexGrow: 1
    },
    container: {
        minWidth: 320,
        maxWidth: 400,
        height: 'auto',
        left: 0,
        right: 0,
        margin: 'auto'
    },
    grow: {
        flexGrow: 1,
    },
    button: {
        minWidth: 320,
        margin: 10,

    },

});

let fileReader;

class Importer extends React.Component {


    state = {
        json: null,
        loading: false
    };

    handleLoadEnd = e => {
        if (fileReader) {
            const content = fileReader.result;
            let json = JSON.parse(csvJSON(content, 'amount;width;height;material;name;ambient;user;moduleId;pieceId;address;neighborhoodAndCity;phone'));
            this.props.createPieces(json);
            this.setState({
                json,
                loading: false,
            });
        }
    };

    handleJsonDownload = e => {
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.state.json));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", "export.json");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    onDrop = async e => {
        let file = e.target.files[0];
        if (file) {
            this.setState({loading: true});
            fileReader = new FileReader();
            fileReader.onloadend = this.handleLoadEnd;
            setTimeout(function () {
                fileReader.readAsText(file, 'ISO-8859-1');
            }, 200)
        }
    };

    render() {
        const {classes} = this.props;
        const {loading} = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.container}>

                    <input
                        accept='.csv'
                        className={classes.input}
                        style={{display: 'none'}}
                        id="raised-button-file"
                        onChange={this.onDrop}
                        type="file"/>

                    <label htmlFor="raised-button-file">
                        <Button disabled={loading} color="primary" variant="contained" component="span"
                                className={classes.button}>
                            Upload .csv
                        </Button>
                    </label>

                    {this.state.json ?
                        < Button onClick={this.handleJsonDownload} variant="contained" color="primary" component="span"
                                 className={classes.button}>
                            Download .json
                        </Button> : null}

                </div>
                <Loader loading={loading}/>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return  bindActionCreators({createPieces:createPieces}, dispatch);
}

function mapStateToProps(state) {
    if (state.projects) {
        return {
            projects: state.projects
        };
    } else {
        return {
            projects: []
        };
    }
}

Importer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(Importer);