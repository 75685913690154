const INITIAL_STATE = {
    currentItem: 0,
};

const applyCurrentItem = (state, action) => ({
    ...state,
    currentItem: action.currentItem
});

function menuReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'MENU_ITEM_CLICKED' : {
            return applyCurrentItem(state, action);
        }
        default :
            return state;
    }
}

export default menuReducer;