export function csvJSON(csv, headersLine) {
    let lines = csv.split("\r\n");
    let result = [];
    let headers = headersLine.split(";");
    for (let i = 0; i < lines.length; i++) {
        let obj = {};

        if (lines[i] != "") {
            let currentLine = lines[i].split(";");
            for (let j = 0; j < headers.length; j++) {
                if (j === 4) {
                    currentLine[j] = replaceChars(currentLine[j]);
                }
                obj[headers[j]] = currentLine[j];
            }
            result.push(obj);
        }
    }
    return JSON.stringify(result);
}

function replaceChars(text) {
    let charRegex = /(@\w\w)/g;
    return text.replace(charRegex, '').trim();

}