import React from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getPieceToPack, packPieces} from '../../actions/pieceActions';
import {getVolumes} from '../../actions/volumeActions';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import PieceStatus from "../Status/PieceStatus";
import Tag from "../Printables/Tag";
import PackModuleDialog from "../PackModuleDialog";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";

import Collapse from "@material-ui/core/Collapse/Collapse";
import blue from "@material-ui/core/colors/blue";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReactToPrint from "react-to-print";
import DeliveryOrder from "../Printables/DeliveryOrder";
import * as constants from "../../utils/constants";

const styles = theme => ({
    dialog: {
        backgroundColor: '#fff',
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    pieceIdLayout: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
        flex: 1,
    },
    pieceIdTextField: {
        width: 300,
        flex: 1,
    },
    pieceIdFromGroup: {
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        width: 300,
    },

    list: {
        marginBottom: 30,
    },

    listItem: {
        '&:hover, &:focus': {
            backgroundColor: blue[50],
        },
        '&:active': {
            backgroundColor: blue[50],
        },
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },

    hidden: {display: 'none'},

    tagCover: {
        backgroundColor: '#fff',
        position: 'absolute',
        padding: 10,
        width: '11cm',
        height: '11cm',
        zIndex: -5,
    },

    deliveryOrderCover: {
        backgroundColor: '#fff',
        position: 'absolute',
        padding: 50,
        width: '220mm',
        height: '307mm',
        zIndex: -5,
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class PackagingDialog extends React.Component {
    constructor(props) {
        super();
        this.isFromBarcodeReader = false;
        this.lastTimeStamp = null;
    }

    componentDidUpdate() {
        if (this.props.project && !this.state.isVolumesLoaded && this.props.openPackingDialog) {
            this.setState({isVolumesLoaded: true});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.openPackingDialog && nextProps.project && nextProps.pack && (!this.state.lastPack || nextProps.pack._id !== this.state.lastPack._id)) {
            this.setState({lastPack: nextProps.pack});
            this.props.getVolumes(nextProps.project._id);
        } else if (nextProps.openPackingDialog && nextProps.project && nextProps.volumes && (this.state.lastVolumesLength == null || nextProps.volumes.length !== this.state.lastVolumesLength)) {
            this.setState({lastVolumesLength: nextProps.volumes.length});
            this.props.getVolumes(nextProps.project._id);
        } else if (nextProps.piecesToPack) {
            const newPieces = this.handlePiecesToPack(nextProps.piecesToPack);
            if (newPieces) {
                this.packModuleDialog.show(
                    "Deseja empacotar de todas as peças listadas?",
                    newPieces
                );
            }
        }
    }

    state = {
        currentPieceId: "",
        lastPack: null,
        lastVolumesLength: null,
        isVolumesLoaded: false,
        isTagAssociated: false,
        piecesToPack: [],
    };

    handleClick(projectId) {
        let value;
        if (projectId in this.state) {
            value = !this.state[projectId];
        } else {
            value = true;
        }
        this.setState({[projectId]: value});
    };

    handleClose = (e) => {
        this.props.onClosePackingDialog(e);
        this.setState({isVolumesLoaded: false, isTagAssociated: false});
    };

    handlePack = (e, projectId, pieces) => {
        this.props.packPieces(projectId, pieces);
        this.setState({piecesToPack: []});
    };

    handlePackModuleConfirm = (modulePieces) => {
        const pieces = [...this.state.piecesToPack, ...modulePieces];
        pieces.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        this.setState({piecesToPack: pieces});
    };

    handlePiecesToPack = (piecesToPack) => {
        let newPieces = [];
        let currentPiece;
        for (let i = 0; i < piecesToPack.length; i++) {
            if (piecesToPack[i].pieceId === this.state.currentPieceId) {
                currentPiece = piecesToPack[i];
            } else if (piecesToPack[i].status === constants.STATUS_CUT && this.state.piecesToPack.filter(piece => piece._id === piecesToPack[i]._id).length === 0) {
                newPieces.push(piecesToPack[i]);
            }
        }
        if (currentPiece) {
            const pieces = [...this.state.piecesToPack.filter(piece => piece._id !== currentPiece._id), currentPiece];
            pieces.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            this.setState({piecesToPack: pieces});
        }
        if (newPieces.length > 0) {
            this.setState({newPieces: newPieces});
            if (newPieces.length > 1) {
                this.setState({currentPieceId:""});
                return newPieces;
            }
        }
        this.setState({currentPieceId:""});
        return null;
    };

    handleGetPiece(pieceId, projectId) {
        if (pieceId && projectId) {
            this.props.getPieceAndAddToProps(pieceId, projectId);
            this.setState({currentPieceId: pieceId});
        }
    }

    handleGetPieceEvent = (e, pieceId, projectId) => {
        this.handleGetPiece(pieceId, projectId);
    };

    handleKeyPress = (e, pieceId, projectId) => {
        if (/^\d$/.test(e.key)) {
            if (this.lastTimeStamp) {
                let diff = e.timeStamp - this.lastTimeStamp;
                this.isFromBarcodeReader = diff < 10;
            }
            this.lastTimeStamp = e.timeStamp;
        } else if (e.key === 'Enter') {
            if (this.isFromBarcodeReader) {
                this.handleGetPiece(pieceId, projectId);
            } else {
                this.setState({showDialog: false});
                this.handleGetPiece(pieceId, projectId);
            }
            this.isFromBarcodeReader = false;
        }
    };

    render() {
        const {
            classes,
            openPackingDialog,
            project,
            volumes,
            error,
            checkOrder,
        } = this.props;

        return (
            <div className={classes.dialog}>
                <Dialog
                    fullScreen
                    open={openPackingDialog}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Empacotamento ({project.name})
                            </Typography>
                            <Button color="inherit" onClick={this.handleClose}>
                                Finalizar
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div>
                        <div className={classes.pieceIdLayout}>
                            <Paper>
                                <FormGroup className={classes.pieceIdFromGroup}>
                                    <TextField
                                        onKeyPress={(e) => this.handleKeyPress(e, this.state.currentPieceId, project._id)}
                                        error={error}
                                        autoFocus={true}
                                        className={classes.pieceIdTextField}
                                        label="ID da peça"
                                        fullWidth={true}
                                        value={this.state.currentPieceId}
                                        onChange={event => {
                                            this.setState({currentPieceId: event.target.value});
                                        }}/>

                                    <Button
                                        onClick={(e) => this.handleGetPieceEvent(e, this.state.currentPieceId, project._id)}
                                        color="primary" className={classes.button}>
                                        Adicionar ao pacote
                                    </Button>

                                    <Button
                                        onClick={(e) => this.handlePack(e, project._id, this.state.piecesToPack)}
                                        color="primary"
                                        variant="contained"
                                        className={classes.button}>
                                        Fechar Pacote
                                    </Button>
                                </FormGroup>
                            </Paper>
                        </div>
                        <List>
                            {this.state.piecesToPack.map((piece) => (
                                <ListItem key={piece._id}>
                                    <ListItemText primary={piece.name}
                                                  secondary={piece.width + " x " + piece.height + " / " + piece.pieceId}/>
                                    <PieceStatus piece={piece}/>
                                </ListItem>
                            ))}
                        </List>

                        <List className={classes.list}>
                            {volumes.map((volume) => (
                                <div key={volume._id}>
                                    <ListItem className={classes.listItem}
                                              onClick={() => this.handleClick(volume.volumeId)}>
                                        <ListItemText primary={
                                            <Typography variant="h5"
                                                        style={{color: '#444fb1'}}>{volume.name}</Typography>}
                                        />
                                        <ReactToPrint
                                            trigger={() => <div>
                                                <Button variant="contained" color="primary" className={classes.button}>
                                                    Imprimir etiqueta
                                                </Button>
                                            </div>}
                                            content={() => this.state[volume._id]}/>

                                        <Tag volume={volume} ref={el => {
                                            if (!this.state[volume._id] || (!this.state.isTagAssociated && this.state.isVolumesLoaded)) {
                                                this.setState({[volume._id]: el, isTagAssociated: true})
                                            }
                                        }}/>
                                        <div className={classes.tagCover}/>

                                        {this.state[volume.volumeId] ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItem>

                                    <Collapse in={this.state[volume.volumeId]} timeout="auto" unmountOnExit>
                                        <List>
                                            {volume.pieces.map((piece) => (
                                                <ListItem key={piece._id}>
                                                    <ListItemText primary={<div>
                                                        <Typography variant="h6"
                                                                    style={{color: '#444fb1'}}>{piece.name}</Typography>
                                                        <Typography
                                                            style={{color: '#a7a7a7'}}>Tamanho: {piece.width + " x " + piece.height}</Typography>
                                                        <Typography style={{color: '#a7a7a7'}}>ID da
                                                            peça: {piece.pieceId}</Typography>
                                                    </div>}/>
                                                    <PieceStatus piece={piece}/>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </div>
                            ))}
                        </List>
                    </div>
                </Dialog>

                <PackModuleDialog
                    onRef={dialog => (this.packModuleDialog = dialog)}
                    handlePackModuleAction={this.handlePackModuleConfirm}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        packPieces: packPieces,
        getPieceAndAddToProps: getPieceToPack,
        getVolumes: getVolumes
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        piecesToPack: state.pieces.piecesToPack,
        pack: state.pieces.pack,
        volumes: state.volumes.volumes,
        error: state.pieces.error,
    };
}

PackagingDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(PackagingDialog);
