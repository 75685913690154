import createPalette from "@material-ui/core/es/styles/createPalette";
import createMuiTheme from "@material-ui/core/es/styles/createMuiTheme";

const themeDefault = createMuiTheme({

    palette: createPalette({
        primary: {
            main: "#9b2025"
        },
        secondary: {
            main: "#4e4b4b"
        },
        appBar: {
            height: 57,
            backgroundColor: "#9b2025"
        },
        drawer: {
            width: 230,
            color: "#9b2025"
        },
        raisedButton: {
            primaryColor: "#9b2025",
        }
    }),

});


export default themeDefault;