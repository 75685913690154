import * as types from './actionTypes';
import volumeApi from '../api/VolumeApi';

export function getVolumesSuccess(volumes) {
    return {type: types.GET_VOLUMES_SUCCESS, volumes};
}

export function checkVolumeSuccess(volume) {
    return {type: types.CHECK_VOLUMES_SUCCESS, volume}
}

export function getVolumes(projectId) {
    // make async call to api, handle promise, dispatch action when promise is resolved
    return function (dispatch) {
        return volumeApi.getVolumes(projectId).then(volumes => {
            dispatch(getVolumesSuccess(volumes));
        }).catch(error => {
            throw(error);
        });
    };
}

export function checkVolume(volumeId, projectId) {
    return function (dispatch) {
        return volumeApi.checkVolume(volumeId, projectId).then(responseVolume => {
            dispatch(checkVolumeSuccess(responseVolume));
        }).catch(error => {
            throw(error);
        });
    };
}




