import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {compose} from "recompose";
import 'react-overlay-loader/styles.css';
import Chip from "@material-ui/core/Chip/Chip";
import Avatar from "@material-ui/core/Avatar/Avatar";
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/WarningRounded';
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import * as constants from '../../utils/constants'

const styles = theme => ({
    chip: {
        margin: theme.spacing.unit,
        marginRight: 30,
        marginLeft: 30,
    },
    greenAvatar: {
        color: '#fff',
        backgroundColor: green[500],
    },
    yellowAvatar: {
        color: '#fff',
        backgroundColor: yellow[800],
    },
    redAvatar: {
        color: '#fff',
        backgroundColor: red[500],
    },

});

class PieceStatus extends React.Component {

    state = {};

    getPieceStatusComponent(status) {
        const {classes} = this.props;
        if (status === constants.STATUS_CUT) {
            return <Chip
                avatar={
                    <Avatar className={classes.yellowAvatar}>
                        <CheckIcon className={classes.icon}/>
                    </Avatar>
                }
                label="Cortado"
                className={classes.chip}/>
        } else if (status === constants.STATUS_PENDING_CUT) {
            return <Chip
                avatar={
                    <Avatar className={classes.redAvatar}>
                        <WarningIcon className={classes.icon}/>
                    </Avatar>
                }
                label="Não foi cortado"
                className={classes.chip}/>
        } else if (status === constants.STATUS_PACKED) {
            return <Chip
                avatar={
                    <Avatar className={classes.greenAvatar}>
                        <CheckIcon className={classes.icon}/>
                    </Avatar>
                }
                label="Empacotado"
                className={classes.chip}/>
        } else {
            return <div/>;
        }
    };

    render() {
        const {piece} = this.props;
        return this.getPieceStatusComponent(piece.status);
    }
}
PieceStatus.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
)(PieceStatus);