import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const prodConfig = {
    apiKey: "AIzaSyCkBTHH1G3bTrNbQPYmY6ZUXyNEYzfuktw",
    authDomain: "clmodulados-44fc8.firebaseapp.com",
    databaseURL: "https://clmodulados-44fc8.firebaseio.com",
    projectId: "clmodulados-44fc8",
    storageBucket: "clmodulados-44fc8.appspot.com",
    messagingSenderId: "357023244808"
};

const devConfig = {
    apiKey: "AIzaSyCkBTHH1G3bTrNbQPYmY6ZUXyNEYzfuktw",
    authDomain: "clmodulados-44fc8.firebaseapp.com",
    databaseURL: "https://clmodulados-44fc8.firebaseio.com",
    projectId: "clmodulados-44fc8",
    storageBucket: "clmodulados-44fc8.appspot.com",
    messagingSenderId: "357023244808"
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();

export {
  db,
  auth,
};
