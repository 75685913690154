export function getFileBlob(object) {
    const ambientName = getAmbientName(object);
    const customerName = getCustomerName(object);
    const customerNeighborhood = getCustomerNeighborhood(object);
    const customerAddress = getCustomerAddress(object);
    const customerCity = getCustomerCity(object);
    const customerPhone = getCustomerPhone(object);
    const items = getItems(object);
    if (items) {
        let lines = "";
        for (let i = 0; i < items.length; i++) {
            let line = [];
            line.push(items[i]._attributes.DESCRIPTION);
            line.push(getMaterial(items[i]));
            if (items[i]._attributes.PLATECUTTINGROTATE === 'Y') {
                line.push(items[i]._attributes.DEPTH);
                line.push(items[i]._attributes.WIDTH);
            } else {
                line.push(items[i]._attributes.WIDTH);
                line.push(items[i]._attributes.DEPTH);
            }
            line.push(items[i]._attributes.REPETITION);
            line.push(getTapeSafely(items[i], 1));
            line.push(getTapeSafely(items[i], 2));
            line.push(getTapeSafely(items[i], 3));
            line.push(getTapeSafely(items[i], 4));

            line.push(ambientName);
            line.push(customerName);
            line.push(items[i]._attributes.UNIQUEPARENTID);
            line.push(items[i]._attributes.UNIQUEPARENTID + items[i]._attributes.UNIQUEID);
            line.push(customerAddress);
            line.push(customerNeighborhood + " / " + customerCity);
            line.push(customerPhone);
            lines += line.join(";") + "\r\n";
            line = [];
        }
        return new Blob([lines], {type: 'text/plain'});
    }
    return null;
}

function getTapeSafely(object, tape) {
    try {
        let result = '0';
        switch (tape) {
            case 1:
                result = object.REFERENCES.FITA_BORDA_1._attributes.REFERENCE;
                break;
            case 2:
                result = object.REFERENCES.FITA_BORDA_2._attributes.REFERENCE;
                break;
            case 3:
                result = object.REFERENCES.FITA_BORDA_3._attributes.REFERENCE;
                break;
            case 4:
                result = object.REFERENCES.FITA_BORDA_4._attributes.REFERENCE;
                break;
        }
        return result;
    } catch (e) {
        return '0';
    }
}

function getItems(object) {
    const ambients = object.LISTING.AMBIENTS;
    let items = [];
    if (isObject(ambients)) {
        const data = object.LISTING.AMBIENTS.AMBIENT.CATEGORIES.CATEGORY;
        let dataArray = [];
        if (isObject(data)) {
            dataArray.push(data);
        } else if (isArray(data)) {
            dataArray = data;
        }
        for (let i = 0; i < dataArray.length; i++) {
            if (isObject(dataArray[i].ITEMS.ITEM)) {
                let currentItems = handleReplicatedItems(getItem(dataArray[i].ITEMS.ITEM), dataArray[i].ITEMS.ITEM._attributes.UNIQUEID);
                items = items.concat(currentItems);
            } else if (isArray(dataArray[i].ITEMS.ITEM)) {
                let innerItemsList = dataArray[i].ITEMS.ITEM;

                console.log(innerItemsList.length);

                for (let j = 0; j < innerItemsList.length; j++) {
                    let currentItems = handleReplicatedItems(getItem(innerItemsList[j]), innerItemsList[j]._attributes.UNIQUEID);
                    items = items.concat(currentItems);
                }
            }
        }
    }
    return items;
}

function getItem(itemObj) {
    let itemsList = [];
    if (isObject(itemObj) && itemObj._attributes.COMPONENT === "Y" && !('ITEMS' in itemObj)) {
        itemsList.push(itemObj);
        return itemsList;
    } else if (isObject(itemObj) && itemObj._attributes.COMPONENT === "Y" && 'ITEMS' in itemObj) {
        itemsList.push(itemObj);
        return itemsList.concat(getItem(itemObj.ITEMS.ITEM));
    } else if (isObject(itemObj) && itemObj._attributes.COMPONENT === "N" && 'ITEMS' in itemObj) {
        return itemsList.concat(getItem(itemObj.ITEMS.ITEM));
    } else if (isObject(itemObj) && itemObj._attributes.COMPONENT === "N" && !('ITEMS' in itemObj)) {
        return itemsList;
    } else {
        const [head, ...tail] = itemObj;
        if (head && tail && tail.length > 0) {
            return itemsList.concat(getItem(head), getItem(tail));
        } else if (head) {
            return itemsList.concat(getItem(head));
        } else if (tail && tail.length > 0) {
            return itemsList.concat(getItem(tail));
        } else {
            return itemsList;
        }
    }
}

function handleReplicatedItems(itemsList, modId) {
    let result = [];
    for (let i = 0; i < itemsList.length; i++) {
        if (itemsList[i]._attributes.REPETITION === 1) {
            itemsList[i]._attributes.UNIQUEPARENTID = modId;
            result.push(itemsList[i]);
        } else {
            let repetitionCount = itemsList[i]._attributes.REPETITION;
            let replicatedItem = itemsList[i];
            let replicatedId = replicatedItem._attributes.UNIQUEID;
            let originalIdsList = null;
            if (itemsList[i].METADATAS && itemsList[i].METADATAS.METADATA && itemsList[i].METADATAS.METADATA._attributes) {
                originalIdsList = itemsList[i].METADATAS.METADATA._attributes.VALUE.split(";");
            }
            for (let j = 0; j < repetitionCount; j++) {
                let currentItem = JSON.parse(JSON.stringify(replicatedItem));
                currentItem._attributes.REPETITION = "1";
                currentItem._attributes.UNIQUEPARENTID = modId;
                if (originalIdsList) {
                    currentItem._attributes.UNIQUEID = originalIdsList[j];
                } else {
                    currentItem._attributes.UNIQUEID = (replicatedId - j).toString();
                }
                result.push(currentItem);

                if (currentItem._attributes.HEIGHT === '30' ||
                    currentItem._attributes.HEIGHT === '36' ||
                    currentItem._attributes.HEIGHT === '45' ||
                    currentItem._attributes.HEIGHT === '54') {
                    let newWidth = parseFloat(currentItem._attributes.WIDTH) + 10;
                    let newDepth = parseFloat(currentItem._attributes.DEPTH) + 10;
                    currentItem._attributes.WIDTH = newWidth;
                    currentItem._attributes.DEPTH = newDepth;

                    if (currentItem._attributes.HEIGHT === '30' || currentItem._attributes.HEIGHT === '45') {
                        currentItem.REFERENCES.THICKNESS._attributes.REFERENCE = 15;
                    } else if (currentItem._attributes.HEIGHT === '36' || currentItem._attributes.HEIGHT === '54') {
                        currentItem.REFERENCES.THICKNESS._attributes.REFERENCE = 18;
                    }

                    let newItem = JSON.parse(JSON.stringify(currentItem));
                    result.push(newItem);
                }
            }


        }
    }
    return result;
}

function getMaterial(item) {
    let material = item.REFERENCES.MATERIAL._attributes.REFERENCE;
    let color = "";
    if (item.REFERENCES.MODEL) {
        color = item.REFERENCES.MODEL._attributes.REFERENCE;
    }
    let thickness = item.REFERENCES.THICKNESS._attributes.REFERENCE;
    return material + ' - ' + thickness + "mm." + color
}

function getAmbientName(object) {
    const ambients = object.LISTING.AMBIENTS;
    if (isObject(ambients)) {
        return ambients.AMBIENT._attributes.DESCRIPTION;
    }
    return null;
}

function getCustomerName(object) {
    const dataArray = object.LISTING.CUSTOMERSDATA.DATA;
    for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i]._attributes.ID === 'nomecliente') {
            return dataArray[i]._attributes.VALUE.replace(new RegExp(' ', 'g'), '-');
        }
    }
    return null;
}

function getCustomerAddress(object) {
    const dataArray = object.LISTING.CUSTOMERSDATA.DATA;
    for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i]._attributes.ID === 'endereco') {
            return dataArray[i]._attributes.VALUE.replace(new RegExp(' ', 'g'), '-');
        }
    }
    return null;
}

function getCustomerNeighborhood(object) {
    const dataArray = object.LISTING.CUSTOMERSDATA.DATA;
    for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i]._attributes.ID === 'bairro') {
            return dataArray[i]._attributes.VALUE.replace(new RegExp(' ', 'g'), '-');
        }
    }
    return null;
}

function getCustomerCity(object) {
    const dataArray = object.LISTING.CUSTOMERSDATA.DATA;
    for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i]._attributes.ID === 'cidade') {
            return dataArray[i]._attributes.VALUE.replace(new RegExp(' ', 'g'), '-');
        }
    }
    return null;
}

function getCustomerPhone(object) {
    const dataArray = object.LISTING.CUSTOMERSDATA.DATA;
    for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i]._attributes.ID === 'celular') {
            return dataArray[i]._attributes.VALUE;
        }
    }
    return null;
}

function isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
}

function isArray(value) {
    return value && typeof value === 'object' && value.constructor === Array;
}
