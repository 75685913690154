import React from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {checkPiece, getPieceByOrder, getPiecesByOrder} from '../../actions/pieceActions';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";
import PieceStatus from "../Status/PieceStatus";
import GenericDialog from "../GenericDialog";


const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    pieceIdLayout: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
        flex: 1,
    },
    pieceIdTextField: {
        width: 300,
        flex: 1,
    },
    pieceIdFromGroup: {
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        width: 300,
    }
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class CuttingCheckDialog extends React.Component {
    constructor(props) {
        super();
        this.isFromBarcodeReader = false;
        this.lastTimeStamp = null;
    }

    state = {
        currentPieceId: "",
        lastPiece: null,
        isProjectLoaded: false,
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.pieceToConfirm && (!this.state.lastPiece || nextProps.pieceToConfirm._id !== this.state.lastPiece._id)) {

            this.confirmCutDialog.show(
                "Confirmar corte",
                "Deseja confirmar o corte da peça " + nextProps.pieceToConfirm.name + "?"
            );
        }
    }

    componentDidUpdate() {
        if (this.props.project && !this.state.isProjectLoaded && this.props.openCuttingCheckDialog) {
            this.props.getPiecesByOrder(this.props.project.order);
            this.setState({isProjectLoaded: true});
        }
    }

    handleClose = (e) => {
        this.props.onCloseCuttingCheckDialog(e);
        this.setState({isProjectLoaded: false});
    };

    handleConfirmDialogConfirm = () => {
        this.setState({lastPiece: this.props.pieceToConfirm});
        this.handleCheckCut(this.state.currentPieceId, this.props.project.order);
    };

    handleGetPiece = (e, pieceId, order) => {
        this.props.getPieceByOrder(pieceId, order);
    };

    handleCheckCut(pieceId, order) {
        this.props.checkPiece(pieceId, order);
        this.setState({currentPieceId: ''});
    }


    handleKeyPress = (e, pieceId, order) => {
        if (/^\d$/.test(e.key)) {
            if (this.lastTimeStamp) {
                let diff = e.timeStamp - this.lastTimeStamp;
                this.isFromBarcodeReader = diff < 10;
            }
            this.lastTimeStamp = e.timeStamp;
        } else if (e.key === 'Enter') {
            if (this.isFromBarcodeReader) {
                this.handleCheckCut(pieceId, order);
            } else {
                this.setState({showDialog: false});
                this.props.getPieceByOrder(pieceId, order);
            }
            this.isFromBarcodeReader = false;
        }
    };

    render() {
        const {
            classes,
            openCuttingCheckDialog,
            project,
            pieces,
            error,
            pieceToConfirm,
        } = this.props;

        let pieceName = "";
        if (pieceToConfirm) {
            pieceName = pieceToConfirm.name;
        }
        return (
            <div>
                <Dialog
                    fullScreen
                    open={openCuttingCheckDialog}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Confêrencia de Corte ({project.name})
                            </Typography>
                            <Button color="inherit" onClick={this.handleClose}>
                                FINALIZAR
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div>
                        <div className={classes.pieceIdLayout}>
                            <Paper>
                                <FormGroup className={classes.pieceIdFromGroup}>
                                    <TextField
                                        onKeyPress={(e) => this.handleKeyPress(e, this.state.currentPieceId, project.order)}
                                        autoFocus={true}
                                        error={error}
                                        className={classes.pieceIdTextField}
                                        label="ID da peça"
                                        fullWidth={true}
                                        value={this.state.currentPieceId}
                                        onChange={event => {
                                            this.setState({currentPieceId: event.target.value});
                                        }}/>

                                    <Button
                                        onClick={(e) => this.handleGetPiece(e, this.state.currentPieceId, project.order)}
                                        color="primary" className={classes.button}>
                                        Conferir
                                    </Button>

                                </FormGroup>
                            </Paper>
                        </div>
                        <List>
                            {pieces.map((piece) => (
                                <ListItem key={piece._id}>
                                    <ListItemText primary={<div>
                                        <Typography
                                            style={{color: '#9b2025'}}>{piece.name}</Typography>
                                        <Typography
                                            style={{color: '#a7a7a7'}}>Projeto: {piece.project.name}</Typography>
                                        <Typography
                                            style={{color: '#a7a7a7'}}>Tamanho: {piece.width + " x " + piece.height}</Typography>
                                        <Typography style={{color: '#a7a7a7'}}>ID da
                                            peça: {piece.pieceId}</Typography>
                                    </div>}/>
                                    <PieceStatus piece={piece}/>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Dialog>

                <GenericDialog
                    onRef={dialog => (this.confirmCutDialog = dialog)}
                    onGenericAction={this.handleConfirmDialogConfirm}
                />

            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPiecesByOrder: getPiecesByOrder,
        checkPiece: checkPiece,
        getPieceByOrder: getPieceByOrder
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        pieces: state.pieces.pieces,
        pieceToConfirm: state.pieces.piece,
        error: state.pieces.error
    };
}

CuttingCheckDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(CuttingCheckDialog);