import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {compose} from "recompose";
import {loadProjects, deliverProject} from '../../actions/projectActions';
import 'react-overlay-loader/styles.css';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Collapse from "@material-ui/core/Collapse/Collapse";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import blue from "@material-ui/core/colors/blue";
import ProjectStatus from "../Status/ProjectStatus";
import PieceStatus from "../Status/PieceStatus";
import CuttingCheckDialog from '../CuttingCheckDialog'
import PackagingDialog from '../PackagingDialog'
import GenericDialog from "../GenericDialog";
import VolumeCheckDialog from "../VolumeCheckDialog";
import Typography from "@material-ui/core/Typography";


const styles = theme => ({
    root: {
        width: '100%',
        flexGrow: 1,
        maxHeight: '100%',
        overflow: 'auto'
    },
    list: {
        marginBottom: 30,
    }, listItem: {
        '&:hover, &:focus': {
            backgroundColor: blue[50],
        },
        '&:active': {
            backgroundColor: blue[50],
        },
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },

});

class Projects extends React.Component {

    constructor(props) {
        super();
        props.loadProjects();
        this.state = {
            currentProject: {},
            openCuttingCheckDialog: false,
            openPackingDialog: false,
            openCheckVolumesDialog: false,
            openConfirmDeliverDialog: false,
        };
        this.confirmDeliverDialog = null;
    }

    onOpenCuttingCheckDialog = (e, project) => {
        e.stopPropagation();
        this.setState({
            openCuttingCheckDialog: true,
            currentProject: project
        });
    };

    onCloseCuttingCheckDialog = (e) => {
        e.stopPropagation();
        this.props.loadProjects();
        this.setState({openCuttingCheckDialog: false});
    };

    onOpenPackingDialog = (e, project) => {
        e.stopPropagation();
        this.setState({
            openPackingDialog: true,
            currentProject: project
        });
    };

    onClosePackingDialog = () => {
        this.props.loadProjects();
        this.setState({openPackingDialog: false});
    };

    onOpenCheckVolumesDialog = (e, project) => {
        e.stopPropagation();
        this.setState({
            openCheckVolumesDialog: true,
            currentProject: project
        });
    };

    onCloseCheckVolumesDialog = () => {
        this.props.loadProjects();
        this.setState({openCheckVolumesDialog: false});
    };

    onOpenConfirmDeliverDialog = (e, project) => {
        e.stopPropagation();
        this.confirmDeliverDialog.show(
            "Saindo para entrega",
            "O projeto " + project.name + " está saindo para entrega.",
            project
        );
    };

    onConfirmDeliverProject = (project) => {
        this.props.deliverProject(project);
    };

    handleClick(projectId) {
        let value;
        if (projectId in this.state) {
            value = !this.state[projectId];
        } else {
            value = true;
        }
        this.setState({[projectId]: value});
    };

    render() {
        const {classes, projects} = this.props;

        return (
            <div className={classes.root}>
                <List className={classes.list}>
                    {projects.map((project) => (
                        <div key={project._id}>
                            <ListItem className={classes.listItem} onClick={() => this.handleClick(project._id)}>
                                <ListItemText primary={<Typography variant="h6"
                                                                   style={{color: '#9b2025'}}>{project.name}</Typography>}/>
                                <ProjectStatus project={project}
                                               onOpenCuttingCheckDialog={this.onOpenCuttingCheckDialog}
                                               onOpenPackagingDialog={this.onOpenPackingDialog}
                                               onOpenConfirmDeliverDialog={this.onOpenConfirmDeliverDialog}
                                               onOpenCheckVolumesDialog={this.onOpenCheckVolumesDialog}
                                />
                                {this.state[project._id] ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>

                            <Collapse in={this.state[project._id]} timeout="auto" unmountOnExit>
                                <List>
                                    {project.pieces.map((piece) => (
                                        <ListItem key={piece._id}>
                                            <ListItemText primary={<div>
                                                <Typography
                                                    style={{color: '#444fb1'}}>{piece.name}</Typography>
                                                <Typography
                                                    style={{color: '#a7a7a7'}}>Tamanho: {piece.width + " x " + piece.height}</Typography>
                                                <Typography style={{color: '#a7a7a7'}}>ID da
                                                    peça: {piece.pieceId}</Typography>
                                            </div>}/>
                                            <PieceStatus piece={piece}/>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </div>
                    ))}

                </List>

                <CuttingCheckDialog
                    openCuttingCheckDialog={this.state.openCuttingCheckDialog}
                    onCloseCuttingCheckDialog={this.onCloseCuttingCheckDialog}
                    project={this.state.currentProject}
                />

                <PackagingDialog
                    openPackingDialog={this.state.openPackingDialog}
                    onClosePackingDialog={this.onClosePackingDialog}
                    project={this.state.currentProject}
                />

                <VolumeCheckDialog
                    openCheckVolumesDialog={this.state.openCheckVolumesDialog}
                    onCloseCheckVolumesDialog={this.onCloseCheckVolumesDialog}
                    project={this.state.currentProject}
                />

                <GenericDialog
                    onRef={dialog => (this.confirmDeliverDialog = dialog)}
                    onGenericAction={this.onConfirmDeliverProject}
                />

            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadProjects: loadProjects, deliverProject: deliverProject}, dispatch);
}

function mapStateToProps(state) {
    return {
        projects: state.projects.projects
    };
}

Projects.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(Projects);