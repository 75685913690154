import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function pieceReducer(state = initialState.piecesResponse, action) {
    switch (action.type) {
        case types.GET_PIECE_SUCCESS:
            if (action.piece.error) {
                state.error = true;
            } else {
                state.error = false;
                state.piece = action.piece;
            }
            return JSON.parse(JSON.stringify(state));
        case types.GET_PIECE_TO_PACK:
            if (action.pieces.error) {
                state.error = true;
            } else {
                state.error = false;
                state.piecesToPack = action.pieces;
                state.piecesToPack.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            return JSON.parse(JSON.stringify(state));
        case types.GET_PIECES_SUCCESS:
            if (action.pieces.error) {
                state.error = true;
            } else {
                state.error = false;
                state.pieces = action.pieces;
                state.pieces.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            return JSON.parse(JSON.stringify(state));
        case types.CREATE_PIECE_SUCCESS:
            if (action.piece.error) {
                state.error = true;
            } else {
                state.pieces = [
                    ...state.pieces.filter(piece => piece._id !== action.piece._id),
                    Object.assign({}, action.piece)
                ];
                state.pieces.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            return state;
        case types.CHECK_PIECE_SUCCESS:
            if (action.piece.error) {
                state.error = true;
            } else {
                state.error = false;
                state.pieces = [
                    ...state.pieces.filter(piece => piece._id !== action.piece._id),
                    Object.assign({}, action.piece)
                ];
                state.pieces.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            return JSON.parse(JSON.stringify(state));

        case types.PACK_PIECES_SUCCESS:
            if (action.pack.error) {
                state.error = true;
            } else {
                state.error = false;
                state.pack = action.pack;
                state.piecesToPack = [];
                state.pieces.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            return JSON.parse(JSON.stringify(state));

        case types.CREATE_PIECES_SUCCESS:
            state.pieces.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            state.pieces = action.pieces;
            return state;
        case types.UPDATE_PIECE_SUCCESS:
            return [
                ...state.filter(cat => cat.id !== action.cat.id),
                Object.assign({}, action.cat)
            ];
        case types.DELETE_PIECE_SUCCESS: {
            const newState = Object.assign([], state);
            const indexOfCatToDelete = state.findIndex(piece => {
                return piece.id === action.piece.id
            });
            newState.splice(indexOfCatToDelete, 1);
            return newState;
        }
        default:
            return state;
    }
}
