import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {withRouter} from 'react-router-dom';

import {firebase} from '../../firebase';
import * as routes from '../../constants/routes';

const withAuthorization = (condition) => (Component) => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            firebase.auth.onAuthStateChanged(authUser => {
                if (!condition(authUser)) {
                    this.props.history.push(routes.SIGN_IN);
                } else {
                    this.props.history.push(routes.HOME);
                }
            });
        }

        render() {
            return this.props.authUser ? <Component {...this.props} /> : null;
        }
    }

    const mapStateToProps = (state) => ({
        authUser: state.sessionState.authUser,
    });

    return compose(
        withRouter,
        connect(mapStateToProps),
    )(WithAuthorization);
};

export default withAuthorization;