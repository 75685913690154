import React from 'react';
import {compose} from "recompose";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import PieceStatus from "../Status/PieceStatus";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import blue from "@material-ui/core/colors/blue";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    pieceIdLayout: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
        flex: 1,
    },
    pieceIdTextField: {
        width: 300,
        flex: 1,
    },
    pieceIdFromGroup: {
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        width: 300,
    },

    list: {
        marginBottom: 30,
    },

    listItem: {
        '&:hover, &:focus': {
            backgroundColor: blue[50],
        },
        '&:active': {
            backgroundColor: blue[50],
        },
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
});

class PackModuleDialog extends React.Component {

    componentDidMount() {
        this.props.onRef(this)
    }

    state = {
        showDialog: false,
        title: "",
        message: "",
        modulePieces: [],
    };

    handleClose = (e) => {
        this.setState({showDialog: false});
    };

    handlePackModuleAction = (e, modulePieces) => {
        this.setState({showDialog: false});
        this.props.handlePackModuleAction(modulePieces);
    };

    show(title, modulePieces) {
        this.setState({title, showDialog: true, modulePieces});
    };

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
                    <DialogContent>
                        <List>
                            {this.state.modulePieces.map((piece) => (
                                <ListItem key={piece._id}>
                                    <ListItemText primary={piece.name}
                                                  secondary={piece.width + " x " + piece.height + " / " + piece.pieceId}/>
                                    <PieceStatus piece={piece}/>
                                </ListItem>
                            ))}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}>
                            Cancelar
                        </Button>
                        <Button
                            onClick={(e) => this.handlePackModuleAction(e, this.state.modulePieces)}
                            color="primary" autoFocus>
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


PackModuleDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
)(PackModuleDialog);