export default {
    projectsResponse: {
        projects: [],
    },

    piecesResponse: {
        pieces: [],
        piecesToPack: [],
        piece: null,
        error: false,
        pack: null,
    },

    volumesResponse: {
        volumes: [],
    },
}