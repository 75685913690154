import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {compose} from "recompose";
import 'react-overlay-loader/styles.css';
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import Typography from "@material-ui/core/Typography/Typography";
import {API_HOST} from "../../api/apiConfig";
import Barcode from "react-barcode";

const styles = theme => ({
    chip: {
        margin: theme.spacing.unit,
        marginRight: 30,
        marginLeft: 30,
    },
    greenAvatar: {
        color: '#fff',
        backgroundColor: green[500],
    },
    yellowAvatar: {
        color: '#fff',
        backgroundColor: yellow[800],
    },
    redAvatar: {
        color: '#fff',
        backgroundColor: red[500],
    },
    image: {
        marginTop: 10,
        height: 40,
    },
    tag: {
        position: 'absolute',
        borderStyle: 'solid',
        borderWidth: 1,
        padding: 10,
        width: '10cm',
        height: '10cm',
        zIndex: -5,
    },

    piecesList: {
        float: 'left',
    },

    clientName: {
        color: '#000',
        marginTop: 20,
    },

    phone: {
        color: '#000',
        marginTop: 5,
    },

    volume: {
        color: '#000',
    },

    black: {
        color: '#000',
    },

    listContainer: {
        marginTop: 10,
        columnCount: 2,
    },

    title: {
        bottom: 0,
        right: 0,
        float: 'right',
        textAlign: 'center',
    }
});

class Tag extends React.Component {
    state = {};

    static getAmbientList(volume) {
        let ambientSet = new Set();
        for (let i = 0; i < volume.pieces.length; i++) {
            ambientSet.add(volume.pieces[i].ambient);
        }
        return Array.from(ambientSet).join(',');
    }

    getPieceStatusComponent(volume) {
        const {classes} = this.props;
        if (!volume) {
            return <div/>;
        } else {
            return <div className={classes.tag}>
                <img className={classes.image} src={`${API_HOST}/images/cl_h_fundo_branco.png`}/>
                <div className={classes.title}>
                    <Barcode
                        format={'CODE39'}
                        displayValue={false}
                        width={1.8}
                        height={40}
                        value={volume.volumeId}/>
                    <Typography variant="h5" className={classes.volume}>{volume.name.replace('volume', 'V')}</Typography>
                </div>

                <Typography variant="h6" className={classes.clientName}>{volume.project.client}</Typography>
                <Typography variant="subheading" className={classes.black}>{Tag.getAmbientList(volume)}</Typography>
                <Typography variant="subheading" className={classes.black}>{volume.project.address}</Typography>
                <Typography variant="subheading" className={classes.phone}>{volume.project.phone}</Typography>

                <div className={classes.listContainer}>
                    <tr className={classes.piecesList}>
                        {volume.pieces.map((piece) => (
                            <td>
                                <Typography className={classes.black}>{piece.name + ": " + piece.pieceId}</Typography>
                            </td>
                        ))}
                    </tr>

                </div>

            </div>
        }
    };

    render() {
        const {volume} = this.props;
        return this.getPieceStatusComponent(volume);
    }
}

Tag.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
)(Tag);