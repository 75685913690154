let backendHost;
const apiVersion = 'v1';

const hostname = window && window.location && window.location.hostname;

if (hostname === 'cl-modulados.herokuapp.com') {
    backendHost = '';
} else {
    backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:8000';
}

export function wasSuccessful(reponse) {
    return !(reponse.status !== 200 && reponse.status !== 201);
}

export const API_ROOT = `${backendHost}/api/${apiVersion}`;
export const API_HOST = `${backendHost}`;