import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import withAuthorization from "../Session/withAuthorization";
import {compose} from "recompose";
import {auth} from '../../firebase/index';
import 'react-overlay-loader/styles.css';
import Converter from "../../components/Converter/index";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import MenuListItems from './MenuContainer';
import MediaQuery from 'react-responsive';
import {connect} from "react-redux";
import Importer from "../../components/Importer";
import Projects from "../../components/Projects";
import {API_HOST} from "../../api/apiConfig";
import constants from '../../utils/constants'

const drawerWidth = 240;
const drawerWidthMini = 70;

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        display: 'flex',
        height: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },

    leftIcon: {
        marginRight: 5,
    },
    rightIcon: {
        marginLeft: 5,
    },
    iconSmall: {
        fontSize: 20,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
        marginTop: -7.8
    },
    drawerPaperMini: {
        position: 'relative',
        width: drawerWidthMini,
        marginTop: -7.8
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        minWidth: 0, // So the Typography noWrap works
    },
    toolbar: theme.mixins.toolbar,

    cover: {
        marginTop: 10,
        height:40
    }
});

class HomePage extends React.Component {

    state = {
        anchorEl: null,
        json: null,
        blob: null,
        loading: false
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleLogOut = () => {
        auth.doSignOut();
    };

    render() {
        const {classes} = this.props;
        const {anchorEl} = this.state;
        const {currentItem} = this.props.currentItem;
        const open = Boolean(anchorEl);

        return (
            <div className={classes.root}>
                <AppBar position="absolute" className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            <img className={classes.cover} src={`${API_HOST}/images/cl_h.png`}/>
                        </Typography>
                        <div>
                            <IconButton
                                aria-owns={open ? 'menu-appbar' : null}
                                aria-haspopup="true"
                                onClick={this.handleMenu}
                                color="inherit">
                                <AccountCircle/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={this.handleClose}>
                                <MenuItem onClick={this.handleLogOut}>Logout</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>

                <MediaQuery query="(orientation: landscape)">
                    <Drawer
                        variant="permanent"
                        classes={{paper: classes.drawerPaper,}}>
                        <div className={classes.toolbar}/>
                        <List>
                            <MenuListItems/>
                        </List>
                    </Drawer>
                </MediaQuery>

                <MediaQuery query="(orientation: portrait)">
                    <Drawer
                        variant="permanent"
                        classes={{paper: classes.drawerPaperMini,}}>
                        <div className={classes.toolbar}/>
                        <List>
                            <MenuListItems/>
                        </List>
                    </Drawer>
                </MediaQuery>

                <main className={classes.content}>
                    <div className={classes.toolbar}/>
                    {currentItem === constants.MENU_CONVERTER && <Converter/>}
                    {currentItem === constants.MENU_IMPORTER && <Importer/>}
                    {currentItem === constants.MENU_PROJECTS && <Projects/>}
                </main>


            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentItem: state.menuState
    }
}

HomePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const authCondition = (authUser) => !!authUser;

export default compose(
    withAuthorization(authCondition),
    withStyles(styles),
    connect(mapStateToProps)
)(HomePage);
