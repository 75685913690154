import * as types from './actionTypes';
import projectApi from '../api/ProjectApi';
import pieceApi from "../api/PieceApi";
import {updatePieceSuccess} from "./pieceActions";

export function loadProjectsSuccess(projects) {
    return {type: types.LOAD_PROJECTS_SUCCESS, projects};
}

export function deliverProjectSuccess(project) {
    return {type: types.DELIVER_PROJECT_SUCCESS, project}
}

export function loadProjects() {
    // make async call to api, handle promise, dispatch action when promise is resolved
    return function (dispatch) {
        return projectApi.getAllProjects().then(projects => {
            dispatch(loadProjectsSuccess(projects));
        }).catch(error => {
            throw(error);
        });
    };
}

export function deliverProject(project) {
    return function (dispatch) {
        return projectApi.deliverProject(project).then(responseProject => {
            dispatch(deliverProjectSuccess(responseProject));
        }).catch(error => {
            throw(error);
        });
    };
}