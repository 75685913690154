import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {compose} from "recompose";
import 'react-overlay-loader/styles.css';
import Chip from "@material-ui/core/Chip/Chip";
import Avatar from "@material-ui/core/Avatar/Avatar";
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/WarningRounded';
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import * as constants from '../../utils/constants'
import Button from "@material-ui/core/Button";

const styles = theme => ({
    chip: {
        margin: theme.spacing.unit,
        marginRight: 30,
        marginLeft: 30,
    },
    greenAvatar: {
        color: '#fff',
        backgroundColor: green[500],
    },
    yellowAvatar: {
        color: '#fff',
        backgroundColor: yellow[800],
    },
    redAvatar: {
        color: '#fff',
        backgroundColor: red[500],
    },
    button: {
        margin: theme.spacing.unit,
        marginRight: 30,
        marginLeft: 30,
    },
});

class ProjectStatus extends React.Component {
    state = {};

    handleCheckCuts = (e, project) => {
        e.stopPropagation();
        this.props.onOpenCuttingCheckDialog(e, project);
    };

    handlePacking = (e, project) => {
        e.stopPropagation();
        this.props.onOpenPackagingDialog(e, project);
    };

    handleCheckVolumes = (e, project) => {
        e.stopPropagation();
        this.props.onOpenCheckVolumesDialog(e, project);
    };

    handleDeliver = (e, project) => {
        e.stopPropagation();
        this.props.onOpenConfirmDeliverDialog(e, project);
    };

    getProjectStatusComponent(project) {
        const {classes} = this.props;
        if (project.status === constants.STATUS_CUT) {
            return (<div>
                <Chip
                    avatar={
                        <Avatar className={classes.yellowAvatar}>
                            <CheckIcon className={classes.icon}/>
                        </Avatar>
                    }
                    label="Corte conferido"
                    className={classes.chip}/>
                <Button variant="contained" color="primary" className={classes.button}
                        onClick={(e) => this.handlePacking(e, project)}>
                    Empacotar
                </Button>
            </div>)
        } else if (project.status === constants.STATUS_PENDING_CUT) {
            return (<div>
                <Chip
                    avatar={
                        <Avatar className={classes.redAvatar}>
                            <WarningIcon className={classes.icon}/>
                        </Avatar>
                    }

                    label="Corte pendente"
                    className={classes.chip}/>
                <Button variant="contained" color="primary" className={classes.button}
                        onClick={(e) => this.handleCheckCuts(e, project)}>
                    Conferir corte
                </Button>
            </div>)
        } else if (project.status === constants.STATUS_PACKED) {
            return (<div>
                <Chip
                    avatar={
                        <Avatar className={classes.greenAvatar}>
                            <CheckIcon className={classes.icon}/>
                        </Avatar>
                    }
                    label="Pronto para entrega"
                    className={classes.chip}/>

                <Button variant="contained" color="primary" className={classes.button}
                        onClick={(e) => this.handleCheckVolumes(e, project)}>
                    Conferir Volumes
                </Button>
            </div>)
        } else if (project.status === constants.STATUS_ON_BOARD) {
            return (<div>
                <Chip
                    avatar={
                        <Avatar className={classes.greenAvatar}>
                            <CheckIcon className={classes.icon}/>
                        </Avatar>
                    }
                    label="Pronto para entrega"
                    className={classes.chip}/>

                <Button variant="contained" color="primary" className={classes.button}
                        onClick={(e) => this.handleCheckVolumes(e, project)}>
                    Conferir Volumes
                </Button>

                <Button variant="contained" color="primary" className={classes.button}
                        onClick={(e) => this.handleDeliver(e, project)}>
                    Saiu para entrega
                </Button>
            </div>)
        }else {
            return <div/>
        }
    }

    render() {
        const {project} = this.props;
        return this.getProjectStatusComponent(project);
    }
}


ProjectStatus.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles)
)(ProjectStatus);