import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function volumeReducer(state = initialState.volumesResponse, action) {
    switch (action.type) {
        case types.GET_VOLUMES_SUCCESS:
            if (action.volumes.error) {
                state.error = true;
            } else {
                state.error = false;
                state.volumes = action.volumes;
            }
            return JSON.parse(JSON.stringify(state));
        case types.CHECK_VOLUMES_SUCCESS:
            if (action.volume.error) {
                state.error = true;
            } else {
                state.error = false;
                state.volumes = [
                    ...state.volumes.filter(volume => volume._id !== action.volume._id),
                    Object.assign({}, action.volume)
                ];
                state.volumes.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
            return JSON.parse(JSON.stringify(state));
        default:
            return state;
    }
}
