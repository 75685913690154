import React from 'react';
import {compose} from "recompose";
import withAuthorization from "../../containers/Session/withAuthorization";

const LandingPage = () => <div></div>;

const authCondition = (authUser) => !!authUser;

export default compose(
    withAuthorization(authCondition)
)(LandingPage);


